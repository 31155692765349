<template>
  <div class="page__not__found" v-if="!loading">
    <LogoMain class="logo__error" />
    <h1>
      {{ (store.languageSwitcher == 0) ? "Erreur 404 | " : (store.languageSwitcher == 1) ? "Error 404 | " : "Error 404 | "}}
      <a :href="URI">{{ (store.languageSwitcher == 0) ? "Retour à l'accueil" : (store.languageSwitcher == 1) ? 'Bring me back home'
          : 'Volver a la página de inicio'
      }}</a>
    </h1>
  </div>
</template>

<script setup>
import { URI } from '@/config/config.js'
import LogoMain from '@/components/LogoMain.vue'
import { useLanguage } from '@/config/stores';
const store = useLanguage();
</script>

<style lang="scss" scoped>
.page__not__found {
  display: flex;
  flex-direction: column;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__error {
  fill: $black;
  margin-bottom: 15px;
}

h1 {
  text-align: center;

  a {
    margin-top: 15px;
    color: $main-green;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-md) {
  .logo__error {
    width: 80%;
  }
}
</style>
